import React from 'react';
import Header from "../header";
import Footer from "../../components/common/oceanFreightForwardingFooter";
import OceanFright from '../../components/common/ocean-freight';
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation, I18nextContext ,Link} from "gatsby-plugin-react-i18next";


const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
 const Oceanfreight = () => {
  const { language } = React.useContext(I18nextContext)
  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/product/ocean-freight-software/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/product/ocean-freight-software/`
      const canoncalUrl = buildCanonicalUrl();

    const buildHrefUrl = () =>
  language === "en"
    ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
    : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
const hrefUrl = buildHrefUrl();
  const { t } = useTranslation()
    return (
        <div className="Layout">
            
            <Header/>
            <OceanFright/>
            <Footer/>
            <Helmet>
            <meta charSet="utf-8" />
          <title>{t('Ocean Freight Software - Best Sea Freight Management Software')}</title>
          <Link rel="canonical" href={canoncalUrl} />           
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
          <meta name="description" content={t("Get Logipulse ocean freight software to streamline ocean export and import operations. Our sea/ocean freight management software integrated with numerous modules enables smooth sea cargo movement, simplifying maritime operations.")}/>
          <meta name="Keywords" content={t("ocean freight software, sea freight software, ocean freight management software, sea freight management software")}/>
            </Helmet>
        </div>
    )
}
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Oceanfreight